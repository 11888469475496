<template>
	<div>
		<el-dialog :title="titleWindows" :visible.sync="openWindows" width="950px" append-to-body>
				
				<el-form ref="form" :model="form" label-width="80px">
					<el-form-item label="姓名">
						<el-input v-model="form.name"></el-input>
					</el-form-item>
					
					<el-form-item label="手机号">
						<el-input v-model="form.phone"></el-input>
					</el-form-item>
					
					
					<el-row>
					  <el-col :span="8">
						<el-form-item label="性别">
						  	<el-select v-model="form.sex" placeholder="请选择患者性别">
						  	  <el-option label="男" value="boy"></el-option>
						  	  <el-option label="女" value="girl"></el-option>
						  	</el-select>
						</el-form-item>
					  </el-col>
					  <el-col :span="8">
						<el-form-item label="出身日期">
						      <el-col :span="11">
						        <el-date-picker type="date" placeholder="选择日期" v-model="form.birthdayVal" ></el-date-picker>
						      </el-col>
						  				
						</el-form-item>
					  </el-col>
					  <el-col :span="8">
						<el-form-item label="疾病类型">
							<el-select clearable  filterable v-model="form.diseaseTypeStr" placeholder="请选择患者疾病">
								
								
							  <el-option v-for="(item ,index) in diseaseTypeList" :key="index" :label="item.name" :value="item.name"></el-option>
							</el-select>
						</el-form-item>
						
						<el-form-item>
						    <el-button type="primary" @click="onSubmit">立即创建</el-button>
						</el-form-item>
						
						  
					  </el-col>
					 
					</el-row>
					
					
					
					
					
					
					
					
					
				</el-form>
		
		
		</el-dialog>
		
		
		
	</div>
	
	
</template>

<script>
	import config from '@/common/config.js'
	
	import timeObj from '@/common/time.js'
	
	export default {
		name: "addPatientPanel",
		props: {
		  titleWindows:{type:String,default:"添加患者"},
		  isWindows: {type: Boolean,default: false,},
		},
		watch: {
		  isWindows: {
		    handler(val) {this.openWindows=val;},
		    immediate: true,
		  },
		  openWindows: {
			  
		    handler(val) {
				this.openWindows=val;
				this.$emit("openWindows",val);
			},
			
		    immediate: true,
		  },
		},
		data() {
			return {
				openWindows:false,
				diseaseTypeList:[],
				thisUser:config.getLoginInfo(),
				
				form:{
					name:'',
					phone:'',
					age:'',
					sex:'',
					birthday:'',
					birthdayVal:'',
					diseaseTypeStr:'',
				}
			}
		},
		created() {

		 var  _this=this;
		 config.ajaxAw("/api/auxiliary/getDictionaryKeysList",
		 {dictionaryId: "2c918d5d7ae81992017af1b6c9fa0126"},
		 function(data){
		 	_this.diseaseTypeList = data
		 	
		 })
		 
		},
		methods: {
			retForm(){
				
				this.form={
					name:'',
					phone:'',
					age:'',
					sex:'',
					birthday:'',
					birthdayVal:'',
					diseaseTypeStr:'',
				}
				
			},
			 isString(value) {
			  return typeof value === 'string';
			},
			 calculateAge(birthDate) {
				
				if(this.isString(birthDate)){
					let timestamp = Date.parse(birthDate);

					birthDate=new Date(birthDate);
				}
			    var currentDate = new Date();
			    var years = currentDate.getFullYear() - birthDate.getFullYear();
			    // 如果当前日期还没到生日那一年的生日，则年龄减1
			    if (currentDate.getMonth() < birthDate.getMonth() || 
			        (currentDate.getMonth() == birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
			        years--;
			    }
			    return years;
			},
			onSubmit(){
				
				if(this.form.name.length<1){
						 this.$message({  message: '请输入患者姓名',type: 'warning' });
					return;				 
				}
				if(this.form.phone.length<10){
					this.$message({message: "请输入患者手机号",type: 'warning' });		 
					return;				 
				}
				
				if(this.form.diseaseTypeStr.length<3){
					this.$message({message: "请选择疾病类型",type: 'warning' });
					return;
				}
				
				if(this.form.birthdayVal.length<3){
					this.$message({message: "请选择出身日期",type: 'warning' });
					return;
				}
				if(this.form.sex.length<1){
					this.$message({message: "请选择性别",type: 'warning' });
					return;
				}
				
				this.form.doctorId=this.thisUser.user.id;
				this.form.diabetesType="unknown";
				this.form.age=this.calculateAge(this.form.birthdayVal);
				
				this.form.birthday=timeObj.timeToStr( this.form.birthdayVal,'YYYY-MM-DD');
				
				var _this=this;
				
				console.log(this.form.birthday)
				
				config.ajaxAw("/kongtangweishi/api/userInfo/doctorAppointmentPatient",
				this.form,
				 function(data1){
					 _this.retForm();
					 _this.$message({  message: '创建成功',type: 'success'});
					 _this.openWindows=false;
					 
				})
		
				
				
				
			}
			
		}
		
	
	}
	
	
</script>

<style>
</style>