<template>
	<div>
	
		
		<el-row>
			<el-col :span="24" style="padding: 10px;">
				<h6>预约医生</h6>
				<el-table :data="doctorDataList" style="width: 95%">
				    <el-table-column prop="name" label="医生" width="180"></el-table-column>
				    <el-table-column prop="hospitalName"  width="320" label="医院" >  </el-table-column>
					<el-table-column prop="name"  label="会诊时间" >
						<template >
						{{dateTime}}
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作"  width="120">
					    <template slot-scope="scope">
					        <el-button type="text" size="small"  @click="remDoctor(scope.$index)"> 取消</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-col>
			
			<el-col :span="24" style="padding: 10px;">
				
				<h6>预约患者</h6>
				
				<div style="width: 100px; float: right; margin-right: 50px;">
					<el-button type="text" style="float: right;" @click="openPatient">选择患者</el-button>
				</div>

				<el-table :data="patientDataList" style="width: 95%">
				    <el-table-column prop="name" label="姓名" width="180"></el-table-column>
				    <el-table-column prop="sex"  label="性别" >  
						<template slot-scope="scope">
							
							<div v-if=" scope.row.sex==1 ">男</div>
							<div v-else>女</div>
						</template>
					</el-table-column>
					<el-table-column prop="age"  label="年龄" >  </el-table-column>
					<el-table-column prop="diseaseTypeStr"  label="疾病" >  </el-table-column>
					<el-table-column fixed="right" label="操作"  width="120">
					    <template slot-scope="scope">
					        <el-button type="text" size="small" @click="remPatient(scope.$index)" > 取消</el-button>
						</template>
					</el-table-column>					
				</el-table>
			</el-col>
			
			<el-col :span="24" style="padding:10px 10px;">
				<div class="block">
					<span class="demonstration">病情描述</span>
					<el-input type="textarea" v-model="caseCountent"  placeholder="请输入内容" show-word-limit />
				</div>
			</el-col>
			
			<el-col :span="24" style="padding:10px 10px;">
				<div class="block">
					<span class="demonstration">描述照片</span>
					
					<el-upload
					  action="https://wei.kongtangweishi.com/web/uploadIMG"
					  list-type="picture-card"
					  :on-preview="handlePictureCardPreview"
					  :on-success="handleSuccess"
					  :on-remove="handleRemove">
					  <i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible">
					  <img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
					
				</div>
			</el-col>
			
			
			
			
		</el-row>
		<el-row>
			<el-col :span="24" style="padding: 10px;">
				<el-button type="success"  class="sub_but" @click="subData" >确定提交</el-button>
			</el-col>
		</el-row>

		
		
		<patientPanel :isWindows="isWindows" 
		@openWindows="openWindows"
		@selPatient="selPatient"></patientPanel>
		

	</div>
</template>

<script>
	
	import config from '@/common/config.js'
	
	import timeObj  from '@/common/time.js'

	import patientPanel from '@/components/timeWindows/patient'  
	
	var _this=null;

	export default {
		components: {patientPanel},
		data(){
			return{
				thisUser:config.getLoginInfo(),
				
				
				doctorDataList:[],
				patientDataList:[],
				dataList:[],
				dialogImageUrl: '',
				dialogVisible: false,
				imgServerList:[],//上传的图片地址
				imgServerStr:"",
				caseCountent:"",
				
				isWindows:false,
				dateTime:'',
		  }
		},
		mounted(e){
			console.log("我的 "+this.$route.query.doctorId)
			var _this=this;
			config.ajaxAw("/kongtangweishi/api/kongtang/doctor/doctorList",
			{ids:this.$route.query.doctorId},
			function(data){
				_this.doctorDataList = data;
			});
			
			this.dateTime=this.$route.query.date+" "+this.$route.query.startTime
			
			
			
		},
		watch:{
			// activeName(val){
			// 	this.init("rem");
			// 	console.log("我的变量"+val)
				
			// }
			
		},
		methods:{
			
			subData(){
				
				if(this.doctorDataList.length<1){
						 this.$message({  message: '请选择会诊医生',type: 'warning' });
					return;				 
				}
				if(this.patientDataList.length<1){
						 this.$message({  message: '请选择会诊患者',type: 'warning' });
					return;				 
				}
				if(this.caseCountent.length<1){
						 this.$message({  message: '请填写病例',type: 'warning' });
					return;				 
				}
				
				
				var doctorId=this.doctorDataList[0].id;
				var patientId=this.patientDataList[0].user_id;
				var patientName=this.patientDataList[0].name;
				var time=this.dateTime;
				var _this=this;
				
				var  data={patient_id:patientId,
				patient_name:patientName,
				doctor_id:doctorId,time:time,
				userId:this.thisUser.user.id,
				caseCountent:this.caseCountent,imgs:this.imgServerStr,
				doctorDataListStr:JSON.stringify(this.doctorDataList),
				patientDataListStr:JSON.stringify(this.patientDataList)}
				
				console.log(data);
				
				
				
				config.post("/kongtangweishi/api/consultation/addConsultationsPost",
				data,
				 function(data1){
					 _this.$message({  message: '预约成功！',type: 'success'});
					  _this.$router.push('/myconsultment');


				})
				
				
				
			},
			
			//删除医生
			remDoctor(index){
				console.log(index);
				this.doctorDataList.splice(index, 1);
				
			},
			//删除患者
			remPatient(index){
				console.log(index);
				this.patientDataList.splice(index, 1);
				
			},
			//患者列表选择的患者信息发送过来了
			
			selPatient(obj){
				console.log(obj)
				this.patientDataList.push(obj);
			},
			//患者列表
			openWindows(val){
				this.isWindows=val;
			},
			//打开患者列表
			openPatient(){
				this.isWindows=true;
			},
			//更新图片地址
			upDataImgList(fileList){
				this.imgServerList=[];
				this.imgServerStr="";
				for (var i = 0; i < fileList.length; i++) {
					var item=fileList[i];
					this.imgServerList.push(item.response.data);
					this.imgServerStr+=item.response.data;
					if(i!=0){
						this.imgServerStr+=";"
					}
				}
			},
			/***
			图片上传处理
			//response
			**/
			handleRemove(file, fileList) {
			    console.log(file, fileList);
				this.upDataImgList(fileList);
			},
			handlePictureCardPreview(file) {
			        this.dialogImageUrl = file.url;
			        this.dialogVisible = true;
			},
			handleSuccess(response, file, fileList){
				 console.log(file, fileList);
				 this.upDataImgList(fileList);
				
			}
		
		
		}
		
		  
	}
</script>

<style>
	
	.time_panel{margin: 10px 20px;text-align: center}
	.time_panel_week{font-size: 12px;color: #999;}
	.time_panel_date{font-size: 16px;}
	
	.time_panel.primary{color: #409EFF;}
	.time_panel.primary .time_panel_week{color: #409EFF;}
	
	.uni-group{
		justify-content:left;
		margin: 10px;
	}
	.demonstration{
		margin: 10px 10px;
		padding: 10px 0px;
		display: block;
	}
	.sub_but{
		float: right;
		margin: 10px 20px;
		
	}

</style>
