<template>
	<div>
		<el-dialog :title="titleWindows" :visible.sync="openWindows" width="950px" append-to-body>
			
			<el-form ref="queryForm" size="small" :inline="true"  label-width="68px">
			  <el-form-item label="名称" prop="keys">
				  <el-input v-model="keys"  placeholder="请输入名称" clearable  @keyup.enter.native="search" />
			  </el-form-item>
			  <el-form-item>
			    <el-button type="primary" icon="el-icon-search" size="mini" @click="search">搜索</el-button>
			  </el-form-item>
			</el-form>
			
			<div class="uni-group">
				
				<div style="width: 700px;">
					<el-button type="success" @click="isAddWindows=true" 
					style="float: right;"
					 size="mini">添加患者</el-button>
				</div>
				
			</div>
			
			
			<el-table :v-loading="loading" :data="dataList" style="width: 100%">
				  <el-table-column  label="患者" prop="name" width="150" />
				  <el-table-column  label="性别" prop="sex" width="120" >
					  <template slot-scope="scope">		
								  
						<div class="sex" v-if="scope.row.sex==1">男</div>
						<div class="sex" v-else>女</div>
						
					  </template>
				  </el-table-column>
				  <el-table-column  label="年龄" prop="age" width="150" />
				  <el-table-column  label="疾病" prop="diseaseTypeStr" width="150" />
				  <el-table-column  label="操作" prop="patient_name" width="120" >
				  		<template slot-scope="scope">					
							 <el-button type="primary"  @click="selPatient(scope.row)"  size="mini">预约</el-button>
				  		</template>
				  </el-table-column>
			</el-table>

			
			
			<!-- <uni-table  border stripe type="selection" :emptyText="$t('common.empty')" @selection-change="selectionChange">
				<uni-tr>
					<uni-th width="80" align="center">患者</uni-th>
					<uni-th width="120" align="center">性别</uni-th>
					<uni-th  align="center">年龄</uni-th> 
					<uni-th  align="center">疾病</uni-th> 
					<uni-th width="120" align="center">操作</uni-th>
				</uni-tr>
				<uni-tr v-for="(item ,index) in dataList" :key="index">
					<uni-td> <div class="name">{{item.name}}</div> </uni-td>
					<uni-td width="140">
						<div class="sex" v-if="item.sex==1">男</div>
						<div class="sex" v-else>女</div>
					</uni-td>
					<uni-td>
						<div class="age">{{item.age}}</div>
					</uni-td>
					<uni-td>
						<div class="diseaseType">{{item.diseaseTypeStr}}</div>
					</uni-td>
					<uni-td>
						<button class="uni-button" type="default" size="mini" 
						 @click="selPatient(item)">选择</button>
					</uni-td>
				</uni-tr>
			 </uni-table>
			 
			 <div class="uni-pagination-box">
			     <uni-pagination show-icon :page-size="pageSize" :current="pageCurrent" :total="total" @change="pageChange" />
			 </div> -->
			 
		</el-dialog>
		
		
		<addPatientPanel @openWindows="addOpenWindows"  :isWindows="isAddWindows"></addPatientPanel>
	</div>
	
</template>

<script>
	import config from '@/common/config.js'
	
	import  addPatientPanel  from '@/components/timeWindows/addPatient'
	
	export default {
		components: {addPatientPanel},
		name: "patientPanel",
		props: {
		  titleWindows:{type:String,default:"患者列表"},
		  isWindows: {type: Boolean,default: false,},
		},
		watch: {
		  isWindows: {
		    handler(val) { 
				this.openWindows=val;
				if(val){
					this.getData("rem");
				}
				
			},
		    immediate: true,
		  },
		  openWindows: {
		    handler(val) { this.openWindows=val;this.$emit("openWindows",val);},
		    immediate: true,
		  },
		},
		data() {
			return {
				thisUser:config.getLoginInfo(),
				isAddWindows:false,
				loading:false,
				openWindows:true,
				dataList:[],
				pageSize:1,
				pageCurrent:1,
				total:0,
				keys:'',
				
			}
		},
		created() {
		 
		},
		
		methods: {
			
			
			addOpenWindows(val){
				this.isAddWindows=val;
				this.getData("rem");
				
			},
						
			//选择了患者
			selPatient(item){
				this.$emit("selPatient",item);
				this.openWindows=false;
			},
			//搜索患者
			search(){
				this.getData("rem");
			},
			//分页
			pageChange(e) {
				this.pageCurrent=e.current;
                this.getData()
            },
			
			getData(rem){
				var _this=this;
				this.loading = true;
				if(rem){
					this.pageCurrent=1;
				}
				config.ajaxAw("/kongtangweishi/api/userInfo/appointmentUserList",
				{keys:this.keys,page:this.pageCurrent,isPage:true,
				doctorId: this.thisUser.user.id},
				function(data){
					_this.dataList = data.content
					_this.total = data.totalPages
					_this.loading = false
				})
			}
			
			
			
		}
	}
</script>

<style>
	.uni-group{
		    justify-content: left;
			margin: 10px 0px;
			
	}
</style>